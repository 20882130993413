<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  mounted () {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgb(0, 177, 108, 0.6)')
    this.gradient.addColorStop(0.5, 'rgb(0, 177, 108, 0.5)')
    this.gradient.addColorStop(1, 'rgba(0, 177, 108, 0.4)')
    this.gradient2.addColorStop(0, 'rgb(0, 71, 224, 0.6)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 71, 224, 0.5)')
    this.gradient2.addColorStop(1, 'rgba(0, 71, 224, 0.4)')
    this.renderChart(
      {
        labels: ['Prueba 1', 'Prueba 2'],
        datasets: [
          {
            backgroundColor: [this.gradient, this.gradient2],
            borderColor: ['rgb(0, 177, 108, 0.5)', 'rgb(0, 71, 224, 0.7)'],
            pointBackgroundColor: '#00b16c',
            borderWidth: 2,
            data: [60, 40]
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    )
  }
}
</script>
