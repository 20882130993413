<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data () {
    return {
      gradient: null
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    this.gradient.addColorStop(0, 'rgb(0, 177, 108, 0.7)')
    this.gradient.addColorStop(0.5, 'rgb(0, 177, 108, 0.5)')
    this.gradient.addColorStop(1, 'rgba(0, 177, 108, 0.2)')
    this.renderChart(
      {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio'
        ],
        datasets: [
          {
            label: 'Prueba',
            borderColor: 'rgb(0, 177, 108, 0.6)',
            borderWidth: 2,
            backgroundColor: this.gradient,
            data: [40, 20, 12, 39, 10, 40, 39]
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    )
  }
}
</script>
