<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <notification class="is-info">
        <p>
          <span class="has-text-weight-medium">Estamos trabajando.</span>
          Esta sección pronto estará disponible.
        </p>
      </notification>
      <tiles v-if="showDashboard">
        <card-component title="Gráfico 1" icon="finance" class="tile is-child">
          <AreaChart />
        </card-component>
        <card-component title="Gráfico 2" icon="finance" class="tile is-child">
          <LineChart />
        </card-component>
      </tiles>
      <tiles v-if="showDashboard">
        <card-component title="Gráfico 3" icon="finance" class="tile is-child">
          <BarChart />
        </card-component>
        <card-component title="Gráfico 4" icon="finance" class="tile is-child">
          <PieChart />
        </card-component>
      </tiles>
    </section>
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import Layout from '@/components/Layout'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import AreaChart from '@/components/Dashboard/AreaChart'
import LineChart from '@/components/Dashboard/LineChart'
import BarChart from '@/components/Dashboard/BarChart'
import PieChart from '@/components/Dashboard/PieChart'
import Notification from '@/components/Notification'

export default {
  components: {
    TitleBar,
    Layout,
    Tiles,
    CardComponent,
    AreaChart,
    LineChart,
    BarChart,
    PieChart,
    Notification
  },
  data () {
    return {
      showDashboard: true
    }
  },
  computed: {
    titleStack () {
      return [
        'Reportes',
        'Dashboard'
      ]
    }
  }
}
</script>
