<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      gradient: null,
      gradient2: null
    }
  },
  mounted () {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient.addColorStop(0, 'rgb(0, 177, 108, 0.6)')
    this.gradient.addColorStop(0.5, 'rgb(0, 177, 108, 0.4)')
    this.gradient.addColorStop(1, 'rgba(0, 177, 108, 0.1)')
    this.gradient2.addColorStop(0, 'rgb(0, 71, 224, 0.6)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 71, 224, 0.4)')
    this.gradient2.addColorStop(1, 'rgba(0, 71, 224, 0.1)')
    this.renderChart(
      {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio'
        ],
        datasets: [
          {
            label: 'Prueba 1',
            borderColor: 'rgb(0, 177, 108, 0.6)',
            pointBackgroundColor: '#00b16c',
            borderWidth: 2,
            pointBorderColor: '#00b16c',
            backgroundColor: this.gradient,
            data: [40, 39, 10, 40, 39, 80, 40]
          },
          {
            label: 'Prueba 2',
            borderColor: 'rgb(0, 71, 224, 0.6)',
            pointBackgroundColor: '#0047e0',
            pointBorderColor: '#0047e0',
            borderWidth: 2,
            backgroundColor: this.gradient2,
            data: [60, 55, 32, 10, 2, 12, 53]
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false
      }
    )
  }
}
</script>
